<template>
  <div id="activation">
    <div id="invite-bg">
      <img src="../../../assets/activation.png" />
    </div>
    <div class="bg-wrap">
      <div class="logo"><img src="../../../assets/logo.png" /></div>
      <div class="desc">
        <div class="main">会员兑换中心</div>
        <div class="sub">
          全网<span style="color: #EF6742;">口碑爆棚</span>的任天堂会员拼车平台
        </div>
      </div>
      <input v-model="actCode" placeholder="请输入兑换码" />
      <input v-model="email" placeholder="请输入任天堂账号所绑定的邮箱" />
      <input v-model="repeatEmail" placeholder="请再次确认邮箱" />
      <div class="check-code" @click="activeCode">立即验证，完成兑换</div>
      <div id="order" @click="order">查订单</div>
      <div class="intro-bg">
        <div class="activation-intro">
          <div id="title">
            兑换说明
          </div>
          <div class="intro-text">
            1、一个兑换码只能用一次，兑换完成后系统会在1分钟内给您的邮箱发送家庭邀请邮件，请登录邮箱接受邀请，加入家庭后即是会员。
          </div>
          <div class="intro-text">
            2、请确认好邮箱，如填写错误，请联系客服处理
          </div>
          <div class="intro-text">
            3、更多精彩内容请关注我们的微信公众号
          </div>
          <img src="../../../assets/official.png" />
          <div class="scan-desc">微信扫码（长按保存识别）</div>
        </div>
      </div>
    </div>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
  </div>
</template>
<script>
import Toast from '@/components/Toast'
import { reportError } from '@/utils/log'
import { utils } from '@/utils'
import debounce from '@/utils/debounce'
import { activeCode } from '@/api/carpool/activation'

export default {
  name: 'activation',
  data () {
    return {
      toastMsg: '',
      showToast: false,
      actCode: '',
      email: '',
      repeatEmail: ''
    }
  },
  components: {
    Toast
  },
  methods: {
    order () {
      this.$router.push('/order')
    },
    checkMail (email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    activeCode: debounce(async function () {
      try {
        if (!this.actCode) {
          this.toastMsg = '兑换码不能为空'
          this.showToast = true
          return
        }

        this.actCode = this.actCode.replace(/\s+/g, '')

        if (this.actCode.length !== 10) {
          this.toastMsg = '兑换码输入错误'
          this.showToast = true
          return
        }

        this.email = this.email.replace(/\s+/g, '')
        this.repeatEmail = this.repeatEmail.replace(/\s+/g, '')
        if (!this.checkMail(this.email)) {
          this.toastMsg = '请输入正确的邮箱'
          this.showToast = true
          return
        }

        if (this.email != this.repeatEmail) {
          this.toastMsg = '两次输入的邮箱不一致，请检查'
          this.showToast = true
          return
        }
        this.showLoading = true
        let res = await activeCode({
          email: this.email,
          actCode: this.actCode
        })
        this.showLoading = false
        if (res.data.code == 0) {
          localStorage.setItem('uuid', this.actCode)
          this.toastMsg = '兑换成功，请去邮箱接受家庭邀请'
          this.showToast = true
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        reportError(error)
      }
    }, 200)
  },
  created () {}
}
</script>

<style lang="less" scoped>
#activation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.5rem;
  background: #f2f3e9;
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  min-height: 18rem;
  #invite-bg {
    position: absolute;
    top: 0;
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .bg-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    .logo {
      margin-top: 0.5rem;
      height: 0.56rem;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .desc {
      margin-top: 1.3rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.4rem;
      .main {
        font-size: 0.76rem;
        font-weight: 500;
        color: #339886;
      }
      .sub {
        color: #339886;
        font-size: 0.28rem;
        font-weight: 500;
        margin-top: 0.12rem;
      }
    }
    input {
      width: 65%;
      z-index: 1;
      border: 0.02rem solid #339886;
      height: 0.4rem;
      padding: 0.12rem 0;
      font-size: 0.28rem;
      outline: none;
      border-radius: 0.34rem;
      padding-left: 0.24rem;
      margin-bottom: 0.16rem;
      &::placeholder {
        font-size: 0.28rem;
        color: #c5c6c5;
      }
    }
    .check-code {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(129deg, #fead67 0%, #ee3240 100%);
      box-shadow: 0px 0px 0.08rem 0.04rem rgba(16, 66, 57, 0.18);
      border-radius: 0.14rem;
      font-weight: 500;
      color: #fff2d6;
      width: 5.6rem;
      height: 0.9rem;
      font-size: 0.36rem;
      cursor: pointer;
      margin-top: 3.3rem;
    }
    #order {
      z-index: 1;
      width: 1.63rem;
      height: 0.68rem;
      background: #ebde99;
      box-shadow: 0px 0.04rem 0.1rem 0 #d4c260;
      border-radius: 0.32rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 0px 0.04rem 0.1rem #d4c260;
      font-size: 0.32rem;
      color: #7c580a;
      cursor: pointer;
      margin-top: 0.36rem;
    }
    .intro-bg {
      z-index: 1;
      width: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .activation-intro {
        width: 7.02rem;
        margin-top: 0.36rem;
        background: #fff;
        border-radius: 0.04rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.4rem;
        margin-bottom: 0.24rem;
        #title {
          font-size: 0.32rem;
          font-weight: 500;
          color: #6a6a6a;
          margin-top: 0.24rem;
        }
        .intro-text {
          width: 6.54rem;
          display: flex;
          font-size: 0.28rem;
          color: #5c5c5c;
          font-size: 400;
          justify-content: flex-start;
          margin-top: 0.1rem;
        }
        img {
          width: 3.26rem;
          height: 3.26rem;
        }
        .scan-desc {
          font-size: 0.24rem;
          color: #666666;
        }
      }
    }
  }
}
</style>
